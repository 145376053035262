








import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { useUserStore } from '@/js/lib/store/user';
import IModal from '@/js/components/molecules/IModal.vue';
import SigninSignupForm from '@/js/components/www.iichi.com/pages/signin-signup/SigninSignupForm.vue';

export default defineComponent({
  components: {
    IModal,
    SigninSignupForm,
  },
  props: {
    active: {
      type: String,
      default: '',
    },
    next: {
      type: String,
      default: '',
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const isShow = ref(false);
    const activeRef = ref('signin');
    const from = ref('');
    const enter = ref(false);
    const onClose = () => {
      isShow.value = false;
    };
    const onSubmit = () => {
      if (props.callback) {
        props.callback();
      }
    };
    onMounted(() => {
      activeRef.value = props.active || (userStore.hasSignined ? 'signin' : 'signup');
      from.value = props.next;
      isShow.value = true;
    });

    return {
      isShow,
      activeRef,
      from,
      enter,
      onClose,
      onSubmit,
    };
  },
});
