
























































import { defineComponent, ref, computed, getCurrentInstance } from '@vue/composition-api';
import { type ComponentInternalInstanceWithMQ } from '@/types';
import draggable from 'vuedraggable';
import validate from '@/js/lib/helper/validate';
import { uploadImage } from '@/js/lib/api/upload';
import FileDroppable from '@/js/components/common/ui/file-droppable.vue';
import ImageComment from './ImageComment.vue';
import IModal from '@/js/components/molecules/IModal.vue';

export interface Picture {
  filename: string;
  url: string;
  text?: string;
  loaded?: boolean;
  isShowCommentEdit?: boolean;
}

let modalOpenPosition = 0;
const openDialog = () => {
  modalOpenPosition = window.scrollY;
};
const closeDialog = () => {
  window.scrollTo(0, modalOpenPosition);
};

export default defineComponent({
  components: {
    draggable,
    FileDroppable,
    ImageComment,
    IModal,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: String,
      default: '',
    },
    errorLabel: {
      type: String,
      default: '',
    },
    inputFileNameFormat: {
      type: String,
      default: undefined,
    },
    textareaNameFormat: {
      type: String,
      default: undefined,
    },
    slotSize: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const currentInstance = getCurrentInstance();
    const hasComment = ref(Boolean(props.textareaNameFormat));
    const uploading = ref(false);
    const uploadError = ref('');
    const inputFileEl = ref();
    const pictures = computed({
      get() {
        return (props.value as Picture[]).map((picture) => {
          return { ...picture, isShowCommentEdit: false };
        });
      },
      set(newVal) {
        emit('input', newVal);
      },
    });
    const filter = computed(() => [hasComment.value ? '.uploaded-photo__comment' : false, '.uploaded-photo__delete-picture'].filter(Boolean).join(','));
    const delay = computed(() => ((currentInstance as ComponentInternalInstanceWithMQ).proxy.$mq === 's' ? 50 : 0));
    const availablePictureSlots = computed(() => Math.max(props.slotSize - pictures.value.length, 0));
    const imgLoaded = computed(() => (index: number) => pictures.value[index]?.loaded);
    const inputFileName = computed(() => (index: number) => props.inputFileNameFormat.replace('%d', String(index)));
    const textareaName = computed(() => (index: number) => props.textareaNameFormat.replace('%d', String(index)));
    const uploadedFileName = computed(() => (index: number) => {
      if (pictures.value[index]) {
        return pictures.value[index].filename;
      }

      return '';
    });
    const uploadPictures = async (files: File[]) => {
      if (files.length === 0) {
        return;
      }

      uploadError.value = '';

      const filteredFiles = files.filter((file) => {
        const error = validate.single(file, { presence: { message: '画像ファイルを選択してください。' }, imageFile: true });

        if (error) {
          [uploadError.value] = error;
        }

        return !error;
      });

      if (filteredFiles.length > 0) {
        uploading.value = true;

        try {
          const fileDatas = await Promise.all(
            filteredFiles.map(async (file) => {
              const fileData = await uploadImage(file);
              return fileData;
            }),
          );
          pictures.value = [
            ...pictures.value,
            ...(fileDatas as Picture[]).map((fileData) => {
              return {
                ...fileData,
                isShowCommentEdit: false,
                loaded: false,
              };
            }),
          ];
        } catch {
          uploadError.value = '画像をアップロードできませんでした。';
        } finally {
          uploading.value = false;
        }
      }
    };
    const onPicturesChange = () => {
      const files = Array.prototype.map.call(inputFileEl.value.files, (file) => file);
      uploadPictures(files.slice(0, availablePictureSlots.value));
      inputFileEl.value.value = '';
    };
    const onFileDropped = (files: File[]) => {
      uploadPictures(files.slice(0, availablePictureSlots.value));
    };
    const onLoadImg = (index: number) => {
      pictures.value = pictures.value.map((picture, i) => (i === index ? { ...picture, loaded: true } : picture));
    };
    const onRemovePictureClick = (index: number) => {
      pictures.value = pictures.value.filter((_picture, i) => i !== index);
    };
    const inputFileElClick = () => {
      inputFileEl.value.click();
    };

    return {
      hasComment,
      uploading,
      uploadError,
      inputFileEl,
      pictures,
      filter,
      delay,
      availablePictureSlots,
      imgLoaded,
      inputFileName,
      textareaName,
      uploadedFileName,
      onPicturesChange,
      onFileDropped,
      onLoadImg,
      onRemovePictureClick,
      inputFileElClick,
      openDialog,
      closeDialog,
    };
  },
});
