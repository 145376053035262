















import { defineComponent, ref, computed, onMounted } from '@vue/composition-api';
import jsSHA from 'jssha/dist/sha1';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';

const TTL = 86400;

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      required: true,
    },
    onCampaign: {
      type: Boolean,
      default: false,
    },
    isSale: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const styleObj = {
      '--background-color': props.backgroundColor,
      '--color': props.color,
    } as Record<string, string>;
    const closed = ref(false);
    const key = ref('');
    const visible = computed(() => props.onCampaign && !props.hidden && !closed.value);
    const genKey = () => {
      const sha1 = new jsSHA('SHA-1', 'TEXT');
      sha1.update(props.href);
      key.value = `close-campaign-banner-${sha1.getHash('HEX')}`;
    };
    const onClick = () => {
      closed.value = true;
      setToStorage({ key: key.value, value: true, expirationSeconds: TTL });
    };

    onMounted(() => {
      genKey();
      closed.value = Boolean(getFromStorage({ key: key.value }));
    });

    return {
      styleObj,
      visible,
      onClick,
    };
  },
});
