








































import { defineComponent, ref, computed, onMounted, getCurrentInstance } from '@vue/composition-api';
import { type ComponentInternalInstanceWithMQ } from '@/types';
import HeaderHeart from '@/icon/header-heart.svg?component';
import HeaderCart from '@/icon/header-cart.svg?component';
import { useCommonStore } from '@/js/lib/store/common';
import { useUserStore } from '@/js/lib/store/user';
import { useNotificationStore } from '@/js/lib/store/notification';
import { useTodoStore } from '@/js/lib/store/todo';
import { useTimelineStore } from '@/js/lib/store/timeline';
import { useCartStore } from '@/js/lib/store/cart';
import { credentials } from '@/js/lib/api/user';
import { dynamicImageUrl } from '@/js/lib/helper/url';
import { signinSignup } from '@/js/lib/ui/signin-signup';
import HeaderAccount from './HeaderAccount.vue';

const signin = () => {
  signinSignup({
    active: 'signin',
    callback: () => {
      window.location.reload();
    },
  });
};
const signup = () => {
  signinSignup({
    active: 'signup',
    callback: () => {
      window.location.reload();
    },
  });
};

export default defineComponent({
  components: {
    HeaderAccount,
    HeaderHeart,
    HeaderCart,
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const commonStore = useCommonStore();
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    const todoStore = useTodoStore();
    const timelineStore = useTimelineStore();
    const cartStore = useCartStore();
    const isShowMenuPc = ref(false);
    const isShowMenuSp = ref(false);
    const isPreSeller = userStore.hasCredential(credentials.preSeller);
    const activityCount = computed(() => (isPreSeller ? todoStore.totalCount + notificationStore.updatedCount : commonStore.activityCount));
    const onClickAvatar = (e) => {
      if ((currentInstance as ComponentInternalInstanceWithMQ).proxy.$mq === 's') {
        e.preventDefault();
      }
      isShowMenuSp.value = !isShowMenuSp.value;
    };

    onMounted(() => {
      if (userStore.isAuthenticated && !isPreSeller) {
        commonStore.checkActivity();
      }
      cartStore.loadBaskets();
    });

    return {
      userStore,
      timelineStore,
      cartStore,
      isShowMenuPc,
      isShowMenuSp,
      activityCount,
      signin,
      signup,
      onClickAvatar,
      dynamicImageUrl,
    };
  },
});
