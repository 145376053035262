


























































import { defineComponent, onMounted } from '@vue/composition-api';
import { useCommonStore } from '@/js/lib/store/common';
import { useUserStore } from '@/js/lib/store/user';
import { useNotificationStore } from '@/js/lib/store/notification';
import { useTodoStore } from '@/js/lib/store/todo';
import { useMessageStore } from '@/js/lib/store/message';
import { useTimelineStore } from '@/js/lib/store/timeline';
import { credentials } from '@/js/lib/api/user';
import { dynamicImageUrl } from '@/js/lib/helper/url';
import { omitWithCap } from '@/js/lib/helper/number';

export default defineComponent({
  setup() {
    const commonStore = useCommonStore();
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    const todoStore = useTodoStore();
    const messageStore = useMessageStore();
    const timelineStore = useTimelineStore();
    const isPreSeller = userStore.hasCredential(credentials.preSeller);
    const isPromoter = userStore.hasCredential(credentials.promoter);
    const isSeller = userStore.hasCredential(credentials.seller);

    onMounted(() => {
      if (userStore.isAuthenticated) {
        if (isPreSeller) {
          notificationStore.checkUpdated();
          todoStore.loadTodos();
        }
        messageStore.checkUnreadCount();
        timelineStore.checkItemUpdated();
        timelineStore.checkEventUpdated();
      }
    });

    return {
      commonStore,
      userStore,
      notificationStore,
      todoStore,
      messageStore,
      isPreSeller,
      isPromoter,
      isSeller,
      dynamicImageUrl,
      omitWithCap,
    };
  },
});
