

















import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { getHistoryItems, type Item } from '@/js/lib/api/item';
import { dynamicImageUrl } from '@/js/lib/helper/url';

export default defineComponent({
  setup() {
    const rootEl = ref<HTMLElement>();
    const items = ref<Item[]>([]);

    onMounted(() => {
      if ('IntersectionObserver' in window) {
        const rootElObserver = new IntersectionObserver((entries) => {
          entries.forEach(async (entry) => {
            if (entry.isIntersecting) {
              items.value = await getHistoryItems({ limit: 36 });
              rootElObserver.unobserve(rootEl.value);
            }
          });
        });
        rootElObserver.observe(rootEl.value);
      }
    });

    return {
      rootEl,
      items,
      dynamicImageUrl,
    };
  },
});
