// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".signin-signup__wrapper--TXyD9{width:100vw}", "",{"version":3,"sources":["webpack://./assets/src/js/components/common/ui/signin-signup.vue"],"names":[],"mappings":"AAmEA,+BACE,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "signin-signup__wrapper--TXyD9"
};
module.exports = ___CSS_LOADER_EXPORT___;
