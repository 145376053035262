












import { defineComponent, ref } from '@vue/composition-api';
import ITextArea from '@/js/components/molecules/ITextArea.vue';
import IButton from '@/js/components/molecules/IButton.vue';

export default defineComponent({
  components: {
    ITextArea,
    IButton,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const observer = ref();
    const comment = ref(props.text);
    const cancel = () => {
      comment.value = props.text;
      emit('cancel');
    };
    const save = async () => {
      const isValid = await observer.value.validate();
      if (isValid) {
        emit('save', comment.value);
      }
    };

    return {
      observer,
      comment,
      cancel,
      save,
    };
  },
});
