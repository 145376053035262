






























































































































































































































import { defineComponent, ref, computed, onMounted, getCurrentInstance, type PropType } from '@vue/composition-api';
import { type ComponentInternalInstanceWithMQ } from '@/types';
import { categories } from '@/js/data/category';
import { sleep } from '@/js/lib/helper/sleep';
import HeaderIcons from './HeaderIcons.vue';

interface Series {
  id: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  revisedAt: string;
  name: string;
  image: {
    url: string;
    width: number;
    height: number;
  };
  banner: {
    url: string;
    width: number;
    height: number;
  };
  text: string;
}

export default defineComponent({
  components: {
    HeaderIcons,
  },
  props: {
    initialQuery: {
      type: String,
      required: false,
      default: '',
    },
    useH1: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSimple: {
      type: Boolean,
      required: false,
      default: false,
    },
    seriesList: {
      type: Array as PropType<Series[]>,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();
    const modalSearchRef = ref<HTMLElement>();
    const sethover = ref<number | undefined>(undefined);
    const currentModal = ref('');
    const currentCategory = ref('');
    const query = ref('');
    const isSearchOpen = ref(false);
    const isPc = computed(() => (currentInstance as ComponentInternalInstanceWithMQ).proxy.$mq !== 's');
    const cats = computed(() => categories());
    const subcats = computed(() => (categoryId: number) => categories({ parentCategoryId: categoryId }));
    const openSearch = async () => {
      currentModal.value = 'category';
      isSearchOpen.value = true;
      await sleep(100);
      modalSearchRef.value?.focus();
      document.querySelector('body')?.classList.add('header-menu-open');
    };
    const closeSearch = () => {
      document.querySelector('body')?.classList.remove('header-menu-open');
      currentModal.value = '';
      currentCategory.value = '';
      isSearchOpen.value = false;
    };
    const openModal = (target: string) => {
      document.querySelector('body')?.classList.add('header-menu-open');
      currentModal.value = target;
    };
    const closeModal = () => {
      document.querySelector('body')?.classList.remove('header-menu-open');
      currentModal.value = '';
      currentCategory.value = '';
    };
    const openSubCategoryPC = (slug: string) => {
      sethover.value = window.setTimeout(() => {
        currentCategory.value = slug;
      }, 300);
    };
    const closeSubCategoryPC = () => {
      clearTimeout(sethover.value);
    };
    const onSearch = () => {
      if (query.value) {
        window.dataLayer?.push({
          event: 'search',
          search_term: query.value,
        });
      }
    };

    onMounted(() => {
      query.value = props.initialQuery;
    });

    return {
      modalSearchRef,
      currentModal,
      currentCategory,
      query,
      isSearchOpen,
      isPc,
      cats,
      subcats,
      openSearch,
      closeSearch,
      openModal,
      closeModal,
      openSubCategoryPC,
      closeSubCategoryPC,
      onSearch,
    };
  },
});
