// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IHistory__title--Q_qTa{font-size:16px;font-weight:700;margin-bottom:12px}.IHistory__list--QGkAb{display:flex;gap:10px;overflow:scroll}.IHistory__list--QGkAb>li{min-width:calc(8.7% - 10px);padding-bottom:12px;position:relative;width:calc(8.7% - 10px)}.IHistory__list--QGkAb>li:last-child:after{content:\"\";height:1px;left:100%;position:absolute;top:0;width:10px}.IHistory__list--QGkAb>li img{height:auto;width:100%}.IHistory__list--QGkAb>li .IHistory__name--LkwPm{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.IHistory__list--QGkAb>li .IHistory__name--LkwPm a{color:inherit}.IHistory__more--Yxuse{font-size:14px;margin-top:10px;text-align:right}.IHistory__more--Yxuse a{color:inherit;padding-left:12px;position:relative}.IHistory__more--Yxuse a:before{content:\"\";font-family:iichi-icon;font-size:18px;left:-5px;line-height:1;position:absolute;top:-2px}@media only screen and (max-width:639px){.IHistory__title--Q_qTa{margin-bottom:10px}.IHistory__list--QGkAb{margin:0 -10px;padding:0 0 0 10px}.IHistory__list--QGkAb>li{min-width:calc(28.6% - 10px);width:calc(28.6% - 10px)}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/common/IHistory.vue","webpack://./assets/src/scss/base/_variables-icons.scss"],"names":[],"mappings":"AAAA,wBAoDA,cACE,CAAA,eACA,CAAA,kBACA,CAGE,uBAIJ,YACE,CAAA,QACA,CAAA,eACA,CAIE,0BAGF,2BACE,CAAA,mBACA,CAAA,iBACA,CAAA,uBACA,CAIE,2CAGF,UACE,CAAA,UACA,CAAA,SACA,CAAA,iBACA,CAAA,KACA,CAAA,UACA,CAAA,8BAGF,WACE,CAAA,UACA,CAAA,iDAGF,eACE,CAAA,sBACA,CAAA,kBACA,CAAA,mDAEA,aACE,CAAA,uBAMR,cACE,CAAA,eACA,CAAA,gBACA,CAAA,yBAEA,aACE,CAAA,iBACA,CAAA,iBACA,CAAA,gCAEA,WCrDe,CAAA,sBAjEP,CAAA,cDyHN,CAAA,SACA,CAAA,aACA,CAAA,iBACA,CAAA,QACA,CAvEJ,yCAEA,wBALF,kBAMI,CASF,uBALF,cAMI,CAAA,kBACA,CASA,0BANF,4BAOI,CAAA,wBACA,CAtBF","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "IHistory__title--Q_qTa",
	"list": "IHistory__list--QGkAb",
	"name": "IHistory__name--LkwPm",
	"more": "IHistory__more--Yxuse"
};
module.exports = ___CSS_LOADER_EXPORT___;
