






import { defineComponent, ref } from '@vue/composition-api';

const getDroppedFiles = (event: DragEvent) => {
  let files = [];

  if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
    files = Array.prototype.map.call(event.dataTransfer.files, (file: File) => file);
  } else if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
    files = Array.prototype.map.call(event.dataTransfer.items, (item: DataTransferItem) => item.getAsFile());
  }

  return files.filter((file) => file instanceof File);
};

export default defineComponent({
  setup(_props, { emit }) {
    const isDragOver = ref(false);
    const onDragover = (e: DragEvent) => {
      // eslint-disable-next-line no-param-reassign
      e.dataTransfer.dropEffect = 'copy';
      isDragOver.value = true;
    };
    const onDragleave = () => {
      isDragOver.value = false;
    };
    const onDrop = (e) => {
      e.preventDefault();
      isDragOver.value = false;

      const files = getDroppedFiles(e);

      if (files.length > 0) {
        emit('file-dropped', files);
      }
    };

    return {
      isDragOver,
      onDragover,
      onDragleave,
      onDrop,
    };
  },
});
