












import { defineComponent } from '@vue/composition-api';
import { PERMISSION_ID_POLICY_UPDATE_202403 } from '@/js/data/permission';
import { useUserStore } from '@/js/lib/store/user';
import { agree } from '@/js/lib/api/account/account';
import { info, loading } from '@/js/lib/ui';
import IButton from '@/js/components/molecules/IButton.vue';

export default defineComponent({
  components: {
    IButton,
  },
  setup() {
    const useStore = useUserStore();
    const onClickAgree = async () => {
      try {
        await loading({
          asyncFunc: () => agree({ settingId: PERMISSION_ID_POLICY_UPDATE_202403 }),
        });
        useStore.updatePermission({ permissions: [PERMISSION_ID_POLICY_UPDATE_202403] });
      } catch (error) {
        info(error, { fadeOut: false });
      }
    };

    return {
      onClickAgree,
    };
  },
});
