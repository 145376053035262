




import { defineComponent, computed } from '@vue/composition-api';
import { hankaku } from '@/js/lib/helper/hankaku';

export default defineComponent({
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const innerValue = computed({
      get() {
        return hankaku(props.value);
      },
      set(newVal) {
        emit('input', hankaku(newVal));
      },
    });

    return {
      innerValue,
    };
  },
});
