// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageComment__errorWrap--Bf9hr{font-size:14px;margin-top:5px}.ImageComment__error--S_QOJ{color:#ad0008}", "",{"version":3,"sources":["webpack://./assets/src/js/components/common/ui/form/ImageComment.vue","webpack://./assets/src/scss/base/_variables.scss"],"names":[],"mappings":"AA0DA,gCACE,cAAA,CACA,cAAA,CAGF,4BACE,aCvBS","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorWrap": "ImageComment__errorWrap--Bf9hr",
	"error": "ImageComment__error--S_QOJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
