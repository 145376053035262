
























import { defineComponent, ref, computed } from '@vue/composition-api';
import { Notification, Dialog as ElDialog } from 'element-ui';

export default defineComponent({
  components: {
    ElDialog,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: 'シェア',
    },
  },
  setup(props) {
    const active = ref(false);
    const urlForFacebook = computed(() => {
      const url = 'https://www.facebook.com/sharer/sharer.php';
      const params = new URLSearchParams();

      params.append('u', props.url);

      return `${url}?${params.toString()}`;
    });
    const urlForTwitter = computed(() => {
      const url = 'https://twitter.com/intent/tweet';
      const params = new URLSearchParams();

      params.append('url', props.url);
      params.append('related', 'iichi_Japan');
      params.append('via', 'iichi_Japan');

      if (props.title) {
        params.append('text', props.title);
      }

      return `${url}?${params.toString()}`;
    });
    const urlForLine = computed(() => {
      const url = 'https://social-plugins.line.me/lineit/share';
      const params = new URLSearchParams();

      params.append('url', props.url);

      return `${url}?${params.toString()}`;
    });
    const shareOnFacebook = () => {
      window.open(urlForFacebook.value, '', 'width=650,height=450,menubar=no,toolbar=no,scrollbars=yes');
    };
    const shareOnTwitter = () => {
      window.open(urlForTwitter.value, '', 'width=650,height=450,menubar=no,toolbar=no,scrollbars=yes');
    };
    const shareOnLine = () => {
      window.open(urlForLine.value, '', 'width=650,height=450,menubar=no,toolbar=no,scrollbars=yes');
    };
    const copyLink = async () => {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(props.url);
        Notification.success({
          title: 'リンクをコピーしました',
          message: '',
          position: 'bottom-right',
        });
      }
    };

    return {
      active,
      urlForFacebook,
      urlForTwitter,
      urlForLine,
      shareOnFacebook,
      shareOnTwitter,
      shareOnLine,
      copyLink,
    };
  },
});
